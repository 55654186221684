export default class Order{

    constructor(){
        this.clientDataText = document.querySelectorAll('.client_data');
        this.aboPriceText = document.querySelectorAll('.abo_price');
        this.devicePriceText = document.querySelectorAll('.device_price');
        this.etolCountText = document.querySelectorAll('.etol_count');
        this.deviceCountText = document.querySelectorAll('.device_count');

        this.client_nameText = document.querySelectorAll('.client_name');
        this.client_companyText = document.querySelectorAll('.client_company');
        this.client_addressText = document.querySelectorAll('.client_address');
        this.client_address_extraText = document.querySelectorAll('.client_address_extra');
        this.client_postcodeText = document.querySelectorAll('.client_postcode');
        this.client_vat = document.querySelectorAll('.client_vat');
        this.client_cityText = document.querySelectorAll('.client_city');
        this.client_phoneText = document.querySelectorAll('.client_phone');
        this.client_emailText = document.querySelectorAll('.client_email');


        this.contractTextEl = document.querySelector('.agreeEtoll');
        this.contractTextInput = document.querySelector('#contract_text');

        this.nameInput = document.querySelector('#billing_first_name');
        this.companyInput = document.querySelector('#billing_company_name');
        this.addressInput = document.querySelector('#billing_address_1');
        this.postCodeInput = document.querySelector('#billing_postcode');
        this.cityInput = document.querySelector('#billing_city');
        this.vatInput = document.querySelector('#billing_vat');
        this.phoneInput = document.querySelector('#billing_phone');
        this.emailInput = document.querySelector('#billing_email');
    
        
        if (this.aboPriceText.length){
            this.init();
        }
    }
    
    init(){

        this.name = this.nameInput.value;
        this.company = this.companyInput.value;
        this.address = this.addressInput.value;
        this.postCode = this.postCodeInput.value;
        this.city = this.cityInput.value;
        this.vat = this.vatInput.value;
        this.phone = this.phoneInput.value;
        this.email = this.emailInput.value;
        

        this.aboPriceText.forEach(el => {
            el.innerHTML = `${abo_price} PLN`;
        });
        this.devicePriceText.forEach(el => {
            el.innerHTML = `${device_price} PLN`;
        });
        this.etolCountText.forEach(el => {
            el.innerHTML = quantity;
        });
        this.deviceCountText.forEach(el => {
            el.innerHTML = quantity;
        });

        this.nameInput.addEventListener('change', () => {
            this.name = this.nameInput.value;
            this.displayData();
        });
        this.companyInput.addEventListener('change', () => {
            this.company = this.companyInput.value;
            this.displayData();
        });
        this.addressInput.addEventListener('change', () => {
            this.address = this.addressInput.value;
            this.displayData();
        });
        this.postCodeInput.addEventListener('change', () => {
            this.postCode = this.postCodeInput.value;
            this.displayData();
        });
        this.cityInput.addEventListener('change', () => {
            this.city = this.cityInput.value;
            this.displayData();
        });
        this.vatInput.addEventListener('change', () => {
            this.vat = this.vatInput.value;
            this.displayData();
        });
        this.phoneInput.addEventListener('change', () => {
            this.phone = this.phoneInput.value;
            this.displayData();
        });
        this.emailInput.addEventListener('change', () => {
            this.email = this.emailInput.value;
            this.displayData();
        });

        this.displayData();
    }
    
    displayData() {

        this.client_nameText.forEach((el) => {
            el.innerHTML = `${this.name}`;
        });

        this.client_companyText.forEach((el) => {
            el.innerHTML = `${this.company}`;
        });

        this.client_addressText.forEach((el) => {
            el.innerHTML = `${this.address}`;
        });

        this.client_postcodeText.forEach((el) => {
            el.innerHTML = `${this.postCode}`;
        });

        this.client_cityText.forEach((el) => {
            el.innerHTML = `${this.city}`;
        });

        this.client_phoneText.forEach((el) => {
            el.innerHTML = `${this.phone}`;
        });

        this.client_emailText.forEach((el) => {
            el.innerHTML = `${this.email}`;
        });


        this.client_vat.forEach((el) => {
            if (this.vat){
                el.innerHTML = `NIP: ${this.vat}`;
            }else{
                el.innerHTML = '';
            }
        });


        this.updateContractInput();
    }

    updateContractInput(){
        this.contractTextInput.value = this.contractTextEl.innerHTML;
    }
}